import { getImage } from 'gatsby-plugin-image'

import EbookForm from '~/components/forms/EbookForm'
import MainLayout from '~/components/layouts/MainLayout'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import InfoSectionLeadMagnets from '~/views/EbookLandings/components/InfoSectionLeadMagnets'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'
import useUltimateGuideMobileDevelopmentStaticQuery from '~/views/EbookLandings/UltimateGuideMobileDevelopment/useUltimateGuideMobileDevelopmentStaticQuery'

const dataList = [
  'Types of apps and different platforms',
  'Steps in business development on the mobile platform',
  'The development pricing policy',
  'Most common pitfalls in the app development',
  'The right tech stack to develop the app',
]

const UltimateGuideMobileDevelopment = () => {
  const query = useUltimateGuideMobileDevelopmentStaticQuery()
  const imageEbook = getImage(fileToImageLikeData(query.imageEbook))

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="Ultimate Guide to Mobile App Development [Free E-book]"
        description="Have an idea to build your own app from scratch? Or maybe you are a successful app owner looking to explore the everchanging mobile app development environment? We have got you covered!"
        maxWidthTitle="750px"
        maxWidthDescription="750px"
      />

      <InfoSectionLeadMagnets
        imageEbook={imageEbook}
        imageClass="ultimateGuideMobileDevelopment"
        imageMeta="Ultimate Guide to Mobile App Development [Free E-book] | Codica"
        description="Our expert team has prepared a detailed deep dive into the mobile app development realm. This e-book covers essential insights about creating a mobile app, including:"
        dataList={dataList}
      />

      <LazyHydrate whenVisible>
        <ReCaptchaProvider>
          <EbookForm
            dataId="downloadEbookButton"
            formTitle="Download our free guide and know it all!"
            fileName="Ultimate Guide to Mobile App Development"
            urlPath="download-mobile-app-development-e-book"
          />
        </ReCaptchaProvider>
      </LazyHydrate>
    </MainLayout>
  )
}

export default UltimateGuideMobileDevelopment
